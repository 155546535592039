import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';
import { Helmet } from 'react-helmet';

const dataUrl =
  'https://calendly.com/lasalleheating/lasalle-scheduled-service-call';
const srcUrl = 'https://assets.calendly.com/assets/external/widget.js';

const componentDidMount = () => {
  if (typeof document !== `undefined`) {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  }
};

const Contact = (props) => (
  <Layout bodyClass="page-contact">
    <Helmet>{componentDidMount()}</Helmet>
    <SEO title="Consultation" />
    <div className="intro intro-small">
      <div className="container22">
        <h1>Schedule a call with LaSalle:</h1>
        <p>
          At work, with the kids, or not near a phone? Have us call you later
          using the scheduler below!
        </p>
        <div className="calendly-inline-widget" data-url={dataUrl}></div>
      </div>
    </div>
  </Layout>
);
export default Contact;
